import React, {useEffect} from "react";
import EmptyPageBoot from "~@app/boot/EmptyPageBoot"
import useApp from "~@app/component/app/UseApp"
import AuthRedirectWrapper from "~@app/component/auth/AuthRedirectWrapper"

function HomeDispatcher() {
  const app = useApp()
  useEffect(() => {
    app.navigate('blog');
  }, [])

  return null;
}

export default function Index() {
  return (
    <EmptyPageBoot>
      <AuthRedirectWrapper mustAuthenticated>
        <HomeDispatcher/>
      </AuthRedirectWrapper>
    </EmptyPageBoot>
  )
}