import React, {PropsWithChildren} from "react";
import '~@app/scss/index.scss';
import {EmptyPageBootProps} from "~@app/boot/Type";
import {I18nextProvider} from "react-i18next";
import i18n from "~@app/component/I18n";
import AuthContextProvider from "~@app/component/auth/AuthContextProvider";
import AppProvider from "~@app/component/app/AppProvider";
import PageProvider from "~@app/component/page/PageProvider";


export default function SSREmptyPageBoot({children}: PropsWithChildren<EmptyPageBootProps>) {
  return (
    <I18nextProvider i18n={i18n}>
      <AppProvider>
        <AuthContextProvider>
          <PageProvider>
            {children}
          </PageProvider>
        </AuthContextProvider>
      </AppProvider>
    </I18nextProvider>
  )
}